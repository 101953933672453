<template>
  <v-row>
    <v-col cols="6" sm="6" md="6">
      <label class="label-modal">{{ $t("fields.pwd") }}</label>
      <v-text-field
        :color="$primary"
        type="password"
        v-model="MyObject.password"
        :rules="[RULES.required]"
        append-icon="cached"
        @click:append="gerarPassword()"
      ></v-text-field>
    </v-col>
    <v-col cols="6" sm="6" md="6">
      <label class="label-modal">{{ $t("fields.c_pwd") }}</label>
      <v-text-field
        :color="$primary"
        :type="show ? 'text' : 'password'"
        v-model="cpwd"
        :rules="[RULES.required, RULES.match_pwd]"
        @click:append="show = !show"
        :append-icon="!show ? 'visibility' : 'visibility_off'"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import CRUD from "@/mixins/_CRUD"
import RULES from "@/mixins/_RULES"
export default {
  mixins: [CRUD, RULES],
  props: {
    MyObject: Object
  },
  data: () => ({
    cpwd: "",
    show: false
  }),
  computed: {},
  methods: {
    gerarPassword() {
      this.MyObject.password = this.generatePassword()
      this.cpwd = this.MyObject.password
    },
    generatePassword() {
      var length = 8,
        charset =
          "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
        retVal = ""
      for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n))
      }
      return retVal
    }
  }
}
</script>
